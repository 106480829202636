/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

#index-hero .hero-body {
  display: block;
  background: none;
  padding: 0; }
  #index-hero .hero-body .top_slide {
    position: relative;
    z-index: 1;
    transition: 0.8s ease-in-out; }
    #index-hero .hero-body .top_slide .item {
      position: relative;
      width: 100%;
      height: calc(100vh - 4.5rem);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center 100%; }
      @media screen and (max-width: 1024px) {
        #index-hero .hero-body .top_slide .item {
          height: 100vh; } }
      #index-hero .hero-body .top_slide .item:nth-child(1) {
        background-size: auto 100%;
        background-image: url(../img/pc_01_back.png); }
        @media screen and (max-width: 1024px) {
          #index-hero .hero-body .top_slide .item:nth-child(1) {
            background-image: url(../img/sm_02_back.png);
            background-size: auto calc(100% - 200px);
            background-position: center 100%; } }
        @media screen and (max-width: 520px) {
          #index-hero .hero-body .top_slide .item:nth-child(1) {
            background-position: center 200px;
            background-size: 106% auto; } }
        @media screen and (max-width: 414px) {
          #index-hero .hero-body .top_slide .item:nth-child(1) {
            background-position: center 163px; } }
      #index-hero .hero-body .top_slide .item:nth-child(2) {
        background-image: url(../img/top_2.jpg); }
      #index-hero .hero-body .top_slide .item:nth-child(3) {
        background-image: url(../img/top_3.jpg); }
      #index-hero .hero-body .top_slide .item:nth-child(4) {
        background-image: url(../img/top_4.jpg); }
      #index-hero .hero-body .top_slide .item:nth-child(5) {
        background-image: url(../img/top_5.jpg); }
